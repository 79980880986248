<template>
  <div class="content">
    <div class="aside">
      <div class="Module-title">
        <div class="title">运营工作台</div>
      </div>
      <ul class="nev-menu-ul">
        <router-link to="/OperationWorkbench/DemandOrderList" tag="li">
          <div class="item-content">
            <div class="pic">
              <img src="@/assets/img/OwnWorkbench/bar-1.png" />
            </div>
            <div class="name">需求订单</div>
          </div>
        </router-link>
      </ul>
      <ul class="nev-menu-ul">
        <router-link to="/OperationWorkbench/SetActive" tag="li">
          <div class="item-content">
            <div class="pic">
              <img src="@/assets/img/OwnWorkbench/bar-2.png" />
            </div>
            <div class="name">设置营销活动</div>
          </div>
        </router-link>
      </ul>
    </div>
    <div class="main">
      <router-view></router-view>
    </div>
  </div>
</template>

<script>

import NevMenuHome from '@/components/NevMenuHome.vue'
export default {
  data () {
    return {
      // designTap:[
      //   {
      //     name:'印刷订单',
      //     img:require("@/assets/img/shoppingcart.png")
      // },
      // {
      //     name:'帮我下单',
      //     img:require("@/assets/img/shoppingcart.png")
      // }]

    }
  },
  components: {
    NevMenuHome
  }

}
</script>

<style lang="scss" scoped>
.nev-menu-ul {
  list-style-type: none;
  padding: 0;
  margin: 0;
  .router-link-active {
    background-color: #e7e7ef;
    position: relative;
  }
  .router-link-active::after {
    content: " ";
    display: block;
    width: 5px;
    height: 30px;
    background: black;
    position: absolute;
    top: 50%;
    transform: translateY(-50%);
    left: 0;
  }
  li {
    height: 46px;
    line-height: 46px;
    display: flex;
    justify-content: center;
    cursor: pointer;

    .item-content {
      width: 218px;
      height: 46px;
      display: flex;
      justify-content: center;
      &:hover {
        background-color: #f1f2f4;
      }
      .pic {
        width: 20%;
      }
      .name {
        width: 60%;
        text-align: left;
        font-size: 14px;
        font-family: Microsoft YaHei;
        font-weight: 400;
        color: #33383e;
      }
    }
  }
}

.content {
  width: 100%;
  /*width:100%;*/
  margin: auto;
  position: absolute;
  top: 110px;
  bottom: 0;
  background-color: rgba(238, 238, 238, 0.699);
  .aside {
    width: 250px;
    height: 100%;
    padding-top: 15px;
    float: left;
    background-color: white;
    position: relative;
    .Module-title {
      height: 70px;
      border-bottom: 1px solid rgb(219, 219, 219);
      margin-bottom: 20px;
      .title {
        line-height: 70px;
        font-size: 18px;
        font-family: Microsoft YaHei;
        font-weight: bold;
        color: #939ba6;
        img {
          margin-right: 10px;
        }
      }
    }
    .title {
      padding-left: 32px;
      font-size: 12px;
      font-family: Microsoft YaHei;
      font-weight: 400;
      color: #939ba6;
      margin-bottom: 10px;
    }
    .space {
      height: 1px;
      background: rgb(218, 218, 218);
      width: 166px;

      margin: 30px 0 30px 30px;
    }
  }
  .main {
    float: right;
    position: absolute;
    right: 0;
    left: 260px;
    height: 100%;
    background-color: white;
    padding-bottom: 30px;
    overflow-y: scroll;
    overflow-x: hidden;
    box-sizing: border-box;
  }
}
</style>
