var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("div", { staticClass: "content" }, [
    _c("div", { staticClass: "aside" }, [
      _vm._m(0),
      _c(
        "ul",
        { staticClass: "nev-menu-ul" },
        [
          _c(
            "router-link",
            { attrs: { to: "/OperationWorkbench/DemandOrderList", tag: "li" } },
            [
              _c("div", { staticClass: "item-content" }, [
                _c("div", { staticClass: "pic" }, [
                  _c("img", {
                    attrs: {
                      src: require("@/assets/img/OwnWorkbench/bar-1.png")
                    }
                  })
                ]),
                _c("div", { staticClass: "name" }, [_vm._v("需求订单")])
              ])
            ]
          )
        ],
        1
      ),
      _c(
        "ul",
        { staticClass: "nev-menu-ul" },
        [
          _c(
            "router-link",
            { attrs: { to: "/OperationWorkbench/SetActive", tag: "li" } },
            [
              _c("div", { staticClass: "item-content" }, [
                _c("div", { staticClass: "pic" }, [
                  _c("img", {
                    attrs: {
                      src: require("@/assets/img/OwnWorkbench/bar-2.png")
                    }
                  })
                ]),
                _c("div", { staticClass: "name" }, [_vm._v("设置营销活动")])
              ])
            ]
          )
        ],
        1
      )
    ]),
    _c("div", { staticClass: "main" }, [_c("router-view")], 1)
  ])
}
var staticRenderFns = [
  function() {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("div", { staticClass: "Module-title" }, [
      _c("div", { staticClass: "title" }, [_vm._v("运营工作台")])
    ])
  }
]
render._withStripped = true

export { render, staticRenderFns }